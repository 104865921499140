import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html',
  styleUrls: ['./confirmation-dialogue.component.scss']
})
export class ConfirmationDialogueComponent {
  //Message à afficher par défaut si aucun message n'est envoyé depuis le front grâce à la variable customMessage
  defaultMessage: string = 'Êtes-vous sûr de vouloir supprimer cet élément ?';
  customMessage: string; //Message à afficher dans la boîte de dialogue

  titleMessage: string ; //Titre de la boîte de dialogue

  colorButton: string; //Couleur du bouton de confirmation

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.customMessage = data.customMessage;
    this.titleMessage = data.titleMessage;
    this.colorButton = data.colorButton;
  }
}
