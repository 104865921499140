import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  pathrUrl: string = environment.apiUrl;
  user!: UserModel;
  constructor(private http: HttpClient,
    private router: Router,) { }


  initSessionSotorage() {
    if (typeof (sessionStorage) !== "undefined") {
      const userSession = sessionStorage.getItem('user');
      if (userSession != null) {
        const user = JSON.parse(userSession);
        if (user.type_utilisateur == environment.gestionnaire) {
          this.router.navigateByUrl("dashboard/gestionnaire");
        } 
        else if(user.type_utilisateur == environment.admin){
          this.router.navigateByUrl("dashboard/administrateur");
        }

      } 

    }
  }


  verifyUser(formValue: any){
    
    return this.http.post<any>(`${this.pathrUrl}/login`, formValue);
  }

  addUserAuth(user: UserModel){
    this.user= user;
    
  }
}
