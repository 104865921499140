import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false;
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?:any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: "Dashboard",
			icon: "home",
			type: "sub",
			active: true,
			children: [
			  { path: "/dashboard/client", title: "Revenu", type: "link" },
			  { path: "/dashboard/client/depense", title: "Dépense", type: "link" },
			  { path: "/dashboard/client/categorie", title: "Catégorie", type: "link" },
			],
		 },
	];

	MENU_ADMIN: Menu[] = [
		{
			title: "Dashboard",
			icon: "home",
			type: "sub",
			active: true,
			children: [
				{ path: "/dashboard/administrateur", title: "Tableau de bord", type: "link" },
				{ path: "/dashboard/administrateur/magasins", title: "Magasins", type: "link" },
				{ path: "/dashboard/administrateur/gerants", title: "Gérants", type: "link" },
			],
		 }
	];

	MENU_GESTIONNAIRE: Menu[] = [
		{
			title: "Dashboard",
			icon: "home",
			type: "sub",
			active: true,
			children: [
			  { path: "/dashboard/gestionnaire", title: "Tableau de bord", type: "link" },
			  { path: "/dashboard/gestionnaire/articles", title: "Articles", type: "link" },
			  { path: "/dashboard/gestionnaire/ventes", title: "Liste des ventes", type: "link" },
			  { path: "/dashboard/gestionnaire/depenses", title: "Liste des dépenses", type: "link" },
			
			],
		 },
		 {
			title: "Paramétrage",
			icon: "settings",
			type: "sub",
			active: true,
			children: [
				{ path: "/dashboard/gestionnaire/deplacements", title: "Déplacements", type: "link" },
				{ path: "/dashboard/gestionnaire/approvisionnements", title: "Approvisionnements", type: "link" },
				{ path: "/dashboard/gestionnaire/appro_details", title: "Ouvrir un carton", type: "link" },
			  { path: "/dashboard/gestionnaire/produits", title: "Produits", type: "link" },
		
			],
		 },
		 //{ path: "/dashboard/gestionnaire/personnels", icon: "users", title: "sample-page-3", type: "link" },
	];


	// Array
	//items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	getItems(type_compte: string){
		if(type_compte == environment.gestionnaire) return new BehaviorSubject<Menu[]>(this.MENU_GESTIONNAIRE);
		if(type_compte == environment.admin) return new BehaviorSubject<Menu[]>(this.MENU_ADMIN);
		
		return new BehaviorSubject<Menu[]>(this.MENUITEMS);
		
	}


}
