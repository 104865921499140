import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;
  destroy$!: Subject<boolean>;
  connexion = "CONNEXION";
  patienter = "PATIENTER...";
  buttonText = this.connexion; //A afficher au niveau du template


  user!: UserModel;

  public appname: string = environment.app_name;
  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.destroy$ = new Subject<boolean>;  //Destruction de la souscription


    this.authService.initSessionSotorage();
    this.initForm();
  }

  private initForm() {
    this.loginForm = this.formBuilder.group({
      identifiant: ['', Validators.required],
      mot_de_passe: ['', Validators.required]
    });
  }

  // Alert appelé lorsque auth incorrecte
  dangerToastr(msg: string) {
    this.toastrService.error(msg, 'Erreur ', { progressBar: true });
  }
  onSubmitForm() {
    this.buttonText = this.patienter;
    const formValue = {
      "identifiant": this.loginForm.value['identifiant'],
      "mot_de_passe": this.loginForm.value['mot_de_passe'],

    };

    this.authService.verifyUser(formValue).pipe(
      takeUntil(this.destroy$), //Destruction de la soucription
      tap((data) => {
        if (data['status'] == 200) {
          this.user = data['user'];
          if(this.user.status_compte == 1){
            //Session storage
            if (typeof (sessionStorage) !== "undefined") { //Vérifie si le navigateur prend en compte sessionStorage
              sessionStorage.setItem('user', JSON.stringify(this.user));
            }

            this.authService.addUserAuth(this.user); //Mettre l'utilisateur dans une variable globale
          
            
            this.redirect_user_to_account(this.user.type_utilisateur); //Redirection
          }
          else{
            this.dangerToastr(data['Votre compte a été désactivé']);
          }
        }
        else {
          this.buttonText = this.connexion;
          this.dangerToastr(data['message']);
        }

      },
      (error) => {
        this.buttonText = this.connexion;
        this.dangerToastr("Impossible d'atteindre le serveur d'authentification. Veuillez vérifier votre connexion internet, si celà persiste, veuillez contacter le support");
      }
      )
    ).subscribe();

  }

    //Redirection utilisateur

    redirect_user_to_account(type_compte: string) {
    
      switch (type_compte) {
        
        case environment.gestionnaire:
          this.router.navigateByUrl("dashboard/gestionnaire");
          
  
          break;
  
        case environment.vendeur:
          this.router.navigateByUrl("vendeur");
          break;
  
        case environment.admin:
          this.router.navigateByUrl("dashboard/administrateur");
          break;
  
        default:
          this.buttonText = this.connexion;
          this.dangerToastr("Impossible de rediriger vers l'espace correspondante. Veuillez joindre le support informatique");
          break;
      }
    }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
