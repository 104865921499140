<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5 class="text-primary">
          {{ labelDataTable }} <span class="text-success"></span>
        </h5>
      </div>
      <div class="card-body table-responsive">
        <mat-form-field  class="col-md-12 w-100">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Joe Dohn" #input />
        </mat-form-field>
        <table mat-table matSort matSortDirection="desc" aria-describedby="Tableau de donnée" [dataSource]="dataSource" class="mat-elevation-z2">
          <!-- Colonnes -->
          <ng-container *ngFor="let col of columns; index as i" [matColumnDef]="col">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ displayedColumns[i] }}</th>

            <span *ngIf="
                columns[i] != 'actions' &&
                columns[i] != 'actionsAlternance1' &&
                columns[i] != 'actionsAlternance2' &&
                columns[i] != 'buttons' &&
                columns[i] != 'buttonsIcon' &&
                columns[i] != 'badges' &&
                columns[i] != 'image'
              ">
              <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
            </span>
            <!-- action dropdown-->
            <span *ngIf="columns[i] == 'actions'">
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngFor="let action of actions" (click)="action.action(element)">
                    {{ action.label }}
                  </button>
                </mat-menu>
              </td>
            </span>

             <!-- action 1 dropdown-->
             <span *ngIf="columns[i] == 'actionsAlternance1'">
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <ng-container *ngIf=" element[cibleName] == valCondition">
                    <button mat-menu-item *ngFor="let action of actionsAlternance2" (click)="action.action(element)">
                      {{ action.label }}
                    </button>
                  </ng-container>
                  <ng-container *ngIf=" element[cibleName] != valCondition">
                    <button mat-menu-item *ngFor="let action of actionsAlternance1" (click)="action.action(element)">
                      {{ action.label }}
                    </button>
                  </ng-container>
                  
                </mat-menu>
              </td>
            </span>
            <!--Bouton -->
            <span *ngIf="columns[i] == 'buttons'">
              <td mat-cell *matCellDef="let element">
                <button *ngFor="let buttonAction of buttonsAction" [ngClass]="'me-1 btn btn-' + buttonAction.color"
                  (click)="buttonAction.action(element)">
                  {{ buttonAction.label }}
                </button>
              </td>
            </span>

            <!--Bouton Icon -->
            <span *ngIf="columns[i] == 'buttonsIcon'">
              <td mat-cell *matCellDef="let element">
                <button *ngFor="let buttonIconAction of buttonsIconAction"
                  [ngClass]="'me-1 ps-2 pe-2 pb-0 btn btn-' + buttonIconAction.color"
                  (click)="buttonIconAction.action(element)">
                  <mat-icon>{{buttonIconAction.label}}</mat-icon>
                </button>
              </td>
            </span>

            <!--Badge-->
            <span *ngIf="columns[i] == 'badges'">
              <td mat-cell *matCellDef="let element">
                <span [class]="
                    'text-white badge  text-bg-' +
                    getBadgeColor(element[columnStatusName], badges)
                  ">
                  {{ getStatusLabel(element[columnStatusName], badges) }}
                </span>
              </td>
            </span>

             <!--Image-->
             <span *ngIf="columns[i] == 'image'">
              <td mat-cell *matCellDef="let element">
                <img [src]="image.imgPath+'/'+element[image.columnImageName]" alt="" width="50" height="50">
              </td>
            </span>
          </ng-container>

          <!-- Lignes -->
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns" (click)=" rowClickFunction.action(row)"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center p-2" colspan="12">
              Aucune donnée retournée
            </td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="defaultPageSize"
          [length]="data.length"></mat-paginator>
      </div>
    </div>
  </div>
</div>