import { AfterViewInit, Component, Input, OnInit, ViewChild, } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator; //Pour accéder aux instances de la
  @ViewChild(MatSort) sort!: MatSort;


  @Input() data: any[];
  dataSource = new MatTableDataSource<any>;

  /*
  *Stock les noms des colonnes (les clés du tableau json reçu )
  Ex:
  {
    "id":1
  }
  il stockera id
  */
  @Input() columns: string[]; //Les clé du json reçu
  @Input() displayedColumns: string[]; //Les noms des colonnes à afficher dans le template

  @Input() labelDataTable: string; // Texte annoncateur. Ex: Liste des offres




  /*
  * Liste des actions à effectuer
  * On reçoit le label et la méthode à exécuter
  * On aura donc un sous menu du dropdown comportant plusieurs 
  * lien qui exécute une méthode
  * Précision: Dans les pages qui feront appel à ce component, 
  * il faut toujours normé le champs qui représente les actions actions
  * Car une conditions est fait sur ça dans le template
  * action: (params: any) => void signifie qu'on recevra une fonction en paramètre de type void
  */
  @Input() actions: { label: string, action: (params: any) => void }[];


  /*
  * Parfois, parmis les actions à effectuer, on a une action qui peut changer 
  * en fonction da la valeur d'un champs précis

  * actionsAlternance1: C'est un tableau qui contient des objets décrivant des actions. 
  * Chaque objet a deux propriétés : label pour le libellé de l'action et action pour 
  * la fonction à exécuter lorsque l'action est déclenchée.

  * actionsAlternance2: C'est un autre tableau similaire à actionsAlternance1, 
  * mais contenant des actions différentes.
  * Les contients les actions qui et label qui seront remplacé par ceux de actionsAlternance1
  * en fonction du besoin

  * cibleName: C'est une chaîne de caractères qui spécifie le nom de la propriété 
  * dans les données que vous envoyez au composant, où le composant va regarder 
  * pour décider quelle action afficher.

  * valPrim: C'est une chaîne de caractères qui représente la valeur qui, 
  * si correspondante à la valeur de cibleName dans les données, 
  * déclenchera l'affichage des actions du tableau actionsAlternance1. 
  * Sinon, les actions du tableau actionsAlternance2 seront affichées.
  * 
  * Ex: si actionsAlternance1 contient Modfifier, Suspendre
  * cibleName va contenir le champs  qui permet de savoir si c'est activé ou suspendu
  * valPrim contiendra la valeur que ce champs prend quand c'est suspendu
  * Par exemple, si  équivaut 0 à suspendu et 1 équivaut à activé, valPrim prendra pour valeur 0
  * Et celà est défini dans le composant parent qui fait appel au datatable
  * 
  * actionsAlternance2 va alors contenir Modifier, Activer
  * Donc, si certaines lignes du tableau on pour valPrim 0, c'est la tableau actionsAlternance1 qui 
  * sera exécuté, si non, actionsAlternance2 qui sera exécuter
  */
  @Input() actionsAlternance1: { label: string, action: (params: any) => void }[];

  @Input() actionsAlternance2: { label: string, action: (params: any) => void }[];

  //On stocke ici le nom de la colonne qui permet de savoir quelle action sera exécuté
  @Input() cibleName: string;
  //On stocke ici la valeur qui permet de vérifier la condition
  @Input() valCondition: string;

  
  

  /*
  * Liste des boutons à exécuter 
  * On reçoit le label, la couleur du bouton et la méthode à exécuter
  */
  @Input() buttonsAction: { label: string, color: string, action: (params: any) => void }[];


  /*
  * Liste des boutons à exécuter, juste que les libellés sont des icons et non des textes
  * On reçoit le label qui est le nom d'un icon, la couleur du bouton et la méthode à exécuter
  */
    @Input() buttonsIconAction: { label: string, color: string, action: (params: any) => void }[];



  /*
  * Liste des bages ou status:
  * On reçoit le code le label, et la couleur
  * 
  */
  @Input() badges: { code: number, label: string, color: string }[] = [];
  //On doit préciser le nom de la colonne que porte le champs status, dans notre cas, status_mission
  //Car ce champs n'est pas renseigné dans la variable columns mais plutôt badges qui est renseigné
  //C'est utilisé uniquement pour la gestion des status (badges)
  @Input() columnStatusName: string;


  /*
  * rowClickFunction Permet de spécifier la fonction à exécuter lorsque l'utilisateur clique 
  * sur une ligne dans le tableau
  * Elle prend en paramètre la ligne sur laquelle l'utilisateur a cliqué.
  * Elle doit être utilisée pour permettre aux composants parents de définir une fonction de 
  * traitement personnalisée qui sera exécutée lors du clic sur une ligne.
  * Cette fonction est appelé au niveau de de la balise tr dans la datatable
  */
  @Input() rowClickFunction: { action: (params: any) => void };

  /*
  Affichage d'image 
  
  */

  @Input() image: { imgPath: string, columnImageName: string } ;

  //Pagination
  @Input() pageSizeOptions: number[]; //La taille de la page à afficher
  @Input() defaultPageSize: number; // La taille de la page par défaut
  @Input() defaultSort: MatSort; //Pour spécifier le tri par défaut




  ngAfterViewInit() {
    //Initialisation de la pagination
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    

  }


    //Pour le champs de formulaire au niveau du datatable
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

 
   
  ngOnInit() {
    //Initialisation des données
    this.dataSource.data = this.data;
    if (this.defaultSort) {
      this.dataSource.sort = this.defaultSort;
    }

  }

  //Ajout d'un élément en haut  dans la datatable
  addElement(element: any) {
    this.dataSource.data.unshift(element);
    this.dataSource._updateChangeSubscription();
  }

  //Mise à jour d'un element de la datatable
  updateElement(oldElement: any, newElement: any) {
    // On récupère l'index de l'élément à modifier
    const index = this.dataSource.data.indexOf(oldElement);
    //Si l'index existe
    if (index > -1) {
      //On met à jour l'élement
      for (let i in this.columns) {
        this.dataSource.data[index][this.columns[i]] = newElement[this.columns[i]];

      }
    }
  }
  //Suppression d'un élément de la datatable
  removeElement(element: any) {
    const index = this.dataSource.data.indexOf(element);
    if (index > -1) {
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
    }
  }

  /*----Gestion des badges ( ou status) -----*/
//Status et code sont sur any car ont peu avoir des cas où, au lieu d'un nombre, code peut être un mot directement, comment en attente

  getStatusLabel(status: any, mappings: { code: any, label: string, color: string }[]): string {

    const matchingStatus = mappings.find(mapping => mapping.code === status);

    return matchingStatus ? matchingStatus.label : '';
  }

  getBadgeColor(status: any, mappings: { code: any, label: string, color: string }[]): string {
    const matchingStatus = mappings.find(mapping => mapping.code === status);
    return matchingStatus ? matchingStatus.color : '';
  }

  /*------- End gestion des badges -------*/
}
