/*
export const environment = {
  production: false,
  apiUrl: 'http://localhost:8000/api',
  filePath: 'http://localhost:8000',
  app_name:"HAAMARC PLUS",
  admin : "Administrateur",
  gestionnaire : "Gestionnaire",
  vendeur : "Vendeur"
};
*/
export const environment = {
  production: false,
  apiUrl: 'https://hamarc.macotech.tech/backend/api',
  filePath: 'https://hamarc.macotech.tech/backend',
  app_name:"HAAMARC PLUS",
  admin : "Administrateur",
  gestionnaire : "Gestionnaire",
  vendeur : "Vendeur"
};


