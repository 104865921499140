<h2>Choisissez une date</h2>
<mat-dialog-content>
<mat-form-field>
  <input matInput [matDatepicker]="picker" placeholder="Choisissez une date" [(ngModel)]="selectedDate" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
<button mat-button (click)="closeDialog()" class="btn btn-primary text-white">
  Sélectionner
</button>
</mat-dialog-actions>
