<div class="container-fluid p-0">
    <!-- login page start-->
    <div class="authentication-main">
      <div class="row ">
        <div class="col-md-6 bg-login">

        </div>
        <div class="col-md-6 bg-w">
          <div class="auth-innerright">
            <div class="authentication-box">
              <div class="text-center"><h2> {{appname}}</h2></div>
              <div class="card mt-4">
                <div class="card-body">
                  <div class="text-center">
                    <h4>CONNEXION</h4>
                    <h6>Veuilez vous identifier </h6>
                  </div>
                  <form class="theme-form" [formGroup]="loginForm">
                    <div class="form-group">
                      <label class="col-form-label pt-0">Votre identifiant *</label>
                      <input class="form-control" type="text" required="" formControlName="identifiant">
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Votre mot de passe *</label>
                      <input class="form-control" type="password" required="" formControlName="mot_de_passe">
                    </div>

                    <div class="form-group row mt-3 mb-0">
                      <button [disabled]="!loginForm.valid" (click)="onSubmitForm()" class="btn btn-primary btn-block" type="submit">{{buttonText}}</button>
                    </div>
                    
                   
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- login page end-->
  </div>