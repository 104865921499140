import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: "dashboard/gestionnaire",
    loadChildren: () => import("../../components/gestionnaire/gestionnaire.module").then((m)=>m.GestionnaireModule)
  },
  {
    path: "dashboard/administrateur",
    loadChildren: () => import("../../components/administrateur/administrateur.module").then((m)=>m.AdministrateurModule)
  }

];